import {Box, Card, CardActionArea, CardContent, Typography} from "@mui/material";

export default function MyCard({item, setSelected, left = null, right = null}) {
    return (
        <Card sx={{border: 'solid', background: "transparent", marginBottom: 1, borderColor: 'white', borderWidth: 1}}>
            <CardActionArea onClick={() => setSelected(item)}>
                <CardContent sx={{padding: 2}}>
                    <Box display={"flex"} justifyContent={"space-between"} color={'white'}>
                        {left && <Box textAlign={"left"}>
                            <Typography fontWeight={"bold"}>{item[left.primary]}</Typography>
                            <Typography variant={"h7"}>{item[left.secondary]}</Typography>
                        </Box>}
                        {right && <Box textAlign={'right'}>
                            <Typography fontWeight={"bold"}>{item[right.primary]}</Typography>
                            <Typography variant={"h7"}>{item[right.secondary]}</Typography>
                        </Box>}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}