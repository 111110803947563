import MyPanel from "../tools/MyPanel";
import Header from "./Header";
import {Box, Chip, Stack, Typography, CircularProgress} from "@mui/material";
import MyTitle from "../tools/MyTitle";
import {useHistory} from "react-router-dom";
import * as PATHS from "../config/paths";
import FilesCard from "../tools/FilesCard";
import * as DEFAULTS from "../config/defaults";
import {useCallback, useEffect, useRef, useState} from "react";
import {put} from "../services/api";
import {NOTIFICATION, STATUSES_CONTRACT, STATUSES_TASK} from "../config/constants";
import CommentList from "./CommentList";
import {fetchSelectedOffer} from "../redux/actions";
import {useDispatch} from "react-redux";
import {fetchSnackbar} from "../redux/actions";
import BankAccount from "./BankAccount";
import {useNotification} from "../libs/hooks";
import {PaymentIcon} from "../tools/MyIcon";

const TaskItem = ({index, contract, task = DEFAULTS.TASk, updateContract}) => {
    const inputSubmit = useRef(null);
    const [val, setVal] = useState('Cargar');
    const [sending, setSending] = useState(false);
    const [sendButton, setSendButton] = useState(false);
    const dispatch = useDispatch();

    const handleInput = (event) => {
        if (event.target.files.length) {
            setVal(event.target.files[0].name);
            setSendButton(true);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setSending(true);
        put(`${PATHS.CONTRACT_TASK}/${contract.id}/${task.id}`, data).then(contract => {
            updateContract(contract);
            setSending(false);
            setSendButton(false);
            dispatch(fetchSnackbar("Enviado con éxito!"));
        })
        .catch(error => {
            setSending(false);
            setSendButton(false);
            dispatch(fetchSnackbar(error.response.data.message, "error"));
        });
    };

    return (
        <Box component={"form"} onSubmit={handleSubmit} color={"white"} display={"flex"} alignItems={"center"} paddingBottom={1}>
            <input style={{ display: 'none' }} ref={inputSubmit} type="submit" />
            <Box width={'100%'} display={"flex"} alignItems={"center"}>
                <Typography marginRight={1}>{task.description}</Typography>
                <span style={{fontSize: 12}}>({task.statusText})</span>
            </Box>
            { (STATUSES_TASK.APPROVED !== task.status) && <Stack direction={"row"} alignItems={'center'}>
                <label htmlFor={`task${index}`}>
                  <input
                    style={{ display: 'none' }}
                    id={`task${index}`}
                    name="task"
                    type="file"
                    onChange={handleInput}
                  />
                  <Chip component="span" label={val} sx={{color: 'white', maxWidth: 100}}/>
                </label>
                {sending ?
                <CircularProgress size={20} sx={{marginLeft: 1}}/> :
                (sendButton && <Chip label={task.file ? 'Reenviar' : 'Enviar'} sx={{color: 'white'}} onClick={() => inputSubmit.current.click() }/>)}
            </Stack> }
        </Box>
    );
}

export default function ContractDetail({contract, setSelectContract}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const notification = useNotification();
    const [showBankAccount, setShowBankAccount] = useState(true);

    useEffect(() => {
        switch (notification?.model) {
            case NOTIFICATION.CONTRACT:
                setSelectContract(notification.response);
                break;
            default:
        }
    }, [notification, setSelectContract]);

    useEffect(() => {
        setTimeout(() => {
            setShowBankAccount(false);
        }, 3000);
    }, []);

    useEffect(() => {
        let status = new URLSearchParams(history.location.search).get('new');
        setShowBankAccount(status === '1');
    }, [history]);

    const openOffer = useCallback((offer) => {
        history.push(`${PATHS.OFFERS}/${offer.id}`);
        dispatch(fetchSelectedOffer(offer));
    }, [history, dispatch]);

    const Footer = () => {
        return (
            <Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0, minHeight: 56, backgroundColor: '#ffcf00', color: 'white'}}>
                <Box sx={{marginLeft: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center', padding: 1}} onClick={() => setShowBankAccount(!showBankAccount)}>
                    <PaymentIcon fontSize={40}/>
                    <Typography sx={{paddingLeft: 2}}>Datos Financieros y<br/>Estado de Pagos</Typography>
                </Box>
            </Box>
        );
    };

    return (
        <MyPanel
            modelType={4}
            support={`https://api.whatsapp.com/send?phone=${contract.offer.place.phone}`}
            helper={true}
            header={(
                <Box>
                    <Header navButton={<div/>}/>
                    <Box paddingX={5} paddingBottom={2}>
                        <MyTitle name={'CONTRATO'}/>
                        <span>{contract.code}</span>
                    </Box>
                </Box>
            )}
            content={(
                showBankAccount ?
                <Box>
                    <BankAccount contract={contract}/>
                    <Footer/>
                </Box> :
                <Box color={'white'}>
                    <Typography>Estado: {contract.statusText}</Typography>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Chip label={'Ver Oferta'} sx={{color: 'white'}} onClick={() => openOffer(contract.offer)}/>
                        <Chip label={contract.formatCreated} sx={{color: 'white'}}/>
                    </Stack>
                    <FilesCard
                        title={"Subida de archivos:"}
                        items={contract.tasks}
                        component={(item, index) => {
                            return <TaskItem updateContract={setSelectContract} key={index} index={index} contract={contract} task={item}/>;
                        }}
                    />
                    <CommentList
                        hasForm={[STATUSES_CONTRACT.PENDING, STATUSES_CONTRACT.EVALUATING, STATUSES_CONTRACT.HIRED].find((value) => value === parseInt(contract.status))}
                        label={'Agregar nota'}
                        urlStore={`${PATHS.CONTRACT_COMMENT}/${contract.id}`}
                        urlList={`${PATHS.CONTRACT_COMMENTS}/${contract.id}`}
                        height={'30vh'}/>
                    <Footer/>
                </Box>
            )}
        />
    );
}