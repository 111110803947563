import React from 'react';
import ReactDOM from 'react-dom';
import './fonts.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {applyMiddleware, createStore} from "redux";
import reducer from "./redux/reducer";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";

const store = createStore(
    reducer,
    applyMiddleware(thunk)
);

const theme = createTheme({
    typography: {
        fontFamily: 'Galey',
        body1: {
            fontWeight: 'bold',
        },
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
