import {Alert, Snackbar} from "@mui/material";
import {useEffect, useState} from "react";
import {useSnackbar} from "../libs/hooks";
import {useDispatch} from "react-redux";
import {fetchSnackbar} from "../redux/actions";

export default function MySnackbar() {
    const snackbar = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(snackbar.message != null);
    }, [snackbar]);

    const handleClose = () => {
        dispatch(fetchSnackbar(null));
    }

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} sx={{ bottom: 65 }}>
            <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                {snackbar.message }
            </Alert>
        </Snackbar>
    );
}