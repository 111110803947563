import {Card, CardContent, CardHeader} from "@mui/material";
import ScrollBox from "./ScrollBox";

export default function FilesCard({title, items, component, previous = null}) {
    return (
        <Card>
            <CardHeader sx={{background: '#c9c9c7', padding: 1}} titleTypographyProps={{fontSize: 12, color: 'red', fontWeight: 'bold'}} title={title}/>
            <CardContent style={{background: '#959492', paddingBottom: 5, height: '25vh'}}>
                <ScrollBox>
                    {previous}
                    {items.map((item, index) => component(item, index))}
                </ScrollBox>
            </CardContent>
        </Card>
    );
}