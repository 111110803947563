import {Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, styled} from "@mui/material";
import {options} from "../config/menu";
import {useUser} from "../libs/hooks";
import {useHistory} from "react-router-dom";
import {useState} from "react";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function DrawerNavigation({drawerWidth}) {
    const user = useUser();
    const history = useHistory();
    const [currentPath, setCurrentPath] = useState(history.location.pathname);

    const handleItem = (item) => {
        setCurrentPath(item.path);
        history.push(item.path);
    }

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
                display: {xs: 'none', md: 'block'},
            }}
            variant="persistent"
            anchor="left"
            open
        >
            <DrawerHeader/>
            <Divider />
            <List>
                {options.filter((item) => item.public || user).map((item, index) => (
                    <ListItem sx={{ background: currentPath === item.path ? 'lightblue' : 'transparent' }} button key={item.label} onClick={() => handleItem(item)}>
                        <ListItemIcon>
                            <item.icon />
                        </ListItemIcon>
                        <ListItemText primary={item.label}/>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
}