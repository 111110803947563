import {useState} from "react";
import Profile from "./Profile";
import Security from "./Security";
import MyPanel from "../tools/MyPanel";
import Header from "./Header";

export default function Account() {
    const [value, setValue] = useState(0);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <MyPanel
            modelType={2}
            header={(
                <Header/>
            )}
            content={(
                value === 0 ? <Profile changePage={handleChange}/> : <Security changePage={handleChange}/>
            )}
        />
    );
}