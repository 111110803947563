import {Box, Button} from "@mui/material";
import {useState} from "react";
import PasswordField from "../tools/PasswordField";

const initialErrors = {
    password: [null],
};

export default function Reset() {
    const [valid, isValid] = useState(true);
    const [errors, setErrors] = useState(initialErrors);
   
    return (
        <Box sx={{p: 2}}>            
            <Box component={"form"}>                
                <PasswordField
                    name={"new_password"}
                    label={"Nueva contraseña"}
                    valid={errors.password[0] == null}
                    helperText={errors.password[0]}
                />
                <PasswordField
                    name={"password_confirmation"}
                    label={"Confirmar contraseña"}
                    valid={valid}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    GUARDAR
                </Button>
            </Box>            
        </Box> 
    );
}
