import {Box, ButtonBase, Paper, Typography} from "@mui/material";
import {useState} from "react";
import {options} from "../config/menu";
import {useUser} from "../libs/hooks";
import {useHistory} from "react-router-dom";
import {PolyButton} from "../tools/PolyButton";

export default function MobileNavigation() {
    const user = useUser();
    const history = useHistory();
    const [value, setValue] = useState(history.location.pathname);
    const items = options.filter((item) => item.public || user);

    return (
        <Paper sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            display: {xs: 'block', md: 'none'},
            background: '#ffcf00',
            zIndex: 2,
        }}>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                {
                    items
                        .map((item, index) => {
                            const width = 100 / items.length;
                            const fontSize = (value === item.path ? '22px' : '15px');
                            return (
                                <Box sx={{width: '100%'}} key={index}>
                                    { index === 0 ?
                                        <Box sx={{position: 'absolute', width: `${width}%`, textAlign: 'center', left: -30, bottom: -40, zIndex: 1}}>
                                            <PolyButton radius={100} border={"red"} fill={'red'} onClick={() => {
                                                setValue(item.path);
                                                history.push(item.path);
                                            }}>
                                                <Box textAlign={'center'} color={'white'} paddingX={4}>
                                                    <item.icon fontSize={40}/>
                                                    <Typography fontSize={'20px'} lineHeight={'20px'}>{item.label}</Typography>
                                                </Box>
                                            </PolyButton>
                                        </Box> :
                                        <ButtonBase sx={{ height: 'min-content', width: '100%' }} onClick={() => {
                                            setValue(item.path);
                                            history.push(item.path);
                                        }}>
                                            <Box paddingX={3} paddingY={1} style={{textAlign: 'center'}}>
                                                <item.icon/>
                                                <Typography color={'white'} fontSize={fontSize} lineHeight={fontSize}>{item.label}</Typography>
                                            </Box>
                                        </ButtonBase>
                                    }
                                </Box>
                            );
                        })
                }
            </Box>
        </Paper>
    );
}