import associated from '../icons/associated.svg';
import chat from '../icons/chat.svg';
import home from '../icons/home.svg';
import info from '../icons/info.svg';
import payment from '../icons/payment.svg';
import terms from '../icons/terms.svg';

const MyIcon = ({path, fontSize}) => {
    return (
        <img src={path} height={fontSize} alt=""/>
    );
}

export const AssociatedIcon = ({fontSize}) => <MyIcon path={associated} fontSize={fontSize}/>;
export const ChatIcon = ({fontSize}) => <MyIcon path={chat} fontSize={fontSize}/>;
export const HomeIcon = ({fontSize}) => <MyIcon path={home} fontSize={fontSize}/>;
export const InfoIcon = ({fontSize}) => <MyIcon path={info} fontSize={fontSize}/>;
export const PaymentIcon = ({fontSize}) => <MyIcon path={payment} fontSize={fontSize}/>;
export const TermsIcon = ({fontSize}) => <MyIcon path={terms} fontSize={fontSize}/>;
