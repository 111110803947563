import {
    Box,
    Avatar,
    Stack,
    Typography, Link
} from "@mui/material";
import {put} from "../services/api";
import * as PATHS from "../config/paths";
import * as ActionTypes from "../redux/actionTypes";
import {useDispatch} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {useUser} from "../libs/hooks";
import {PhotoCamera} from "@mui/icons-material";
import MyTextField from "../tools/MyTextField";
import {PolyButton} from "../tools/PolyButton";
import MyPanelTitle from "../tools/MyPanelTitle";
import MyButton from "../tools/MyButton";
import {fetchSnackbar} from "../redux/actions";

export default function Profile({changePage}) {
    const [loading, setLoading] = useState(false);
    const user= useUser();
    const sizePhoto = 100;
    const dispatch = useDispatch();
    const [data, setData] = useState({
        email: '',
        name: '',
        documentNumber: '',
        photo: '',
    });
    const fileInput = useRef(null);

    useEffect(() => {
        if (user) {
            setData(user);
        }
    }, [user]);

    const imageHandler = (e) => {
        if (e.target.files.length === 0) {
            setData({...data, photo: user.photo} );
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setData({...data, photo: reader.result} );
            }
        }
        reader.readAsDataURL(e.target.files[0])
    };

    const updateProfile = (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        put(PATHS.USER, data).then(user => {
            dispatch({
                type: ActionTypes.FETCH_USER,
                payload: user,
            });
            setLoading(false);
            dispatch(fetchSnackbar("Datos actualizados con éxito."));
        }).catch(() => {
            setLoading(false);
        });
    }

    return (
        <Box>
            <Stack direction={'row'} alignItems={'start'}>
                <PolyButton radius={60} border={"yellow"} onClick={() => fileInput.current.click()}>
                    {user ?
                        <Avatar sx={{width: sizePhoto, height: sizePhoto}} alt="Remy Sharp" src={data.photo}/> :
                        <PhotoCamera sx={{color: 'gray'}}/>
                    }
                </PolyButton>
                <Box paddingLeft={3} paddingTop={2}>
                    <MyPanelTitle subtitle={"PERFIL"} withLogo={false}/>
                    <Link onClick={() => changePage(1)}>
                        <Typography textAlign={'right'} color={"white"} fontWeight={"bold"}>SEGURIDAD</Typography>
                    </Link>
                </Box>
            </Stack>
            <Box component={"form"} onSubmit={updateProfile}>
                <Box sx={{ display: 'none' }}>
                    <input
                        type="file" accept="image/*" name="photo" id="input" ref={fileInput} onChange={imageHandler}
                    />
                </Box>
                <Box paddingBottom={2}>
                </Box>
                <MyTextField
                    id="name"
                    label="Usuario"   
                    name="name"
                    value={data.name}
                    onChange={(event) => {
                        setData({...data, name: event.currentTarget.value})
                    }}
                />
                <MyTextField
                    id="documentNumber"
                    label="DNI"
                    name={"document_number"}
                    value={data.documentNumber}
                />
                <MyTextField
                    type={"email"}
                    id="email"
                    label="Correo"
                    name={"email"}
                    value={data.email}
                />
                <MyButton
                    type="submit"
                    loading={loading}
                    sx={{ mt: 3, mb: 2 }}
                >
                    GUARDAR
                </MyButton>
            </Box>
        </Box>
    );
}