import MyPanel from "../tools/MyPanel";
import Header from "./Header";
import {Box, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import MobileNavigation from "./MobileNavigation";
import MyPanelTitle from "../tools/MyPanelTitle";
import {TrapezeBox} from "../tools/TrapezeBox";
import {Fragment, useState} from "react";
import CommentList from "./CommentList";
import * as PATHS from "../config/paths";
import {useUser} from "../libs/hooks";

const levels = [
    {
        name: 'A',
        description: 'Beneficios:',
    },
    {
        name: 'B',
        description: 'Beneficios:',
    },
    {
        name: 'C',
        description: 'Beneficios:',
    },
];

export default function Ranking() {
    const [selLevel, setSelLevel] = useState(null);
    const user = useUser();

    const LevelDialog = () => {
        if (!selLevel) return <Fragment/>;
        return (
            <Dialog onClose={() => setSelLevel(null)} open={true}>
                <DialogTitle>{selLevel.name}</DialogTitle>
                <DialogContent>
                    {selLevel.description}
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <MyPanel
            modelType={2}
            header={(
                <Header showHome={true}/>
            )}
            content={(
                <Box>
                    <LevelDialog/>
                    <MyPanelTitle title={"Nuestro"} subtitle={"RANKING"}/>
                    <Box marginTop={5}>
                        <TrapezeBox bg={"#ffcf00"} sx={{width:{md:'180px', xs:'60%'}}} onClick={() => setSelLevel(levels[0])}>
                            <Typography fontSize={25} mb={'-75px'}>{levels[0].name}</Typography>
                        </TrapezeBox>
                        <TrapezeBox bg={"#e53b24"} sx={{width:{md:'300px', xs:'80%'}}} onClick={() => setSelLevel(levels[1])}>
                            <Typography fontSize={25} mb={'-75px'}>{levels[1].name}</Typography>
                        </TrapezeBox>
                        <TrapezeBox bg={"white"} sx={{width:{md:'420px', xs:'100%'}}} onClick={() => setSelLevel(levels[2])}>
                            <Typography fontSize={25} mb={'-75px'}>{levels[2].name}</Typography>
                        </TrapezeBox>
                    </Box>
                    <CommentList
                        hasForm={user !== null}
                        label={'Agregar comentario'}
                        urlStore={`${PATHS.RANKING_COMMENT}`}
                        urlList={`${PATHS.RANKING_COMMENTS}`}
                        height={'28vh'}/>
                </Box>
            )}
            mobileNavigation={<MobileNavigation/>}
        />
    );
}