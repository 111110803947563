import {Box} from "@mui/material";
import { styled } from "@mui/styles";

const CustomScroll = styled(Box)({
    maxHeight: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: 10,
    },
    '&::-webkit-scrollbar-track': {
        borderRadius: 8,
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#797979',
        borderRadius: 8,
        border: '2px solid #f1f2f3',
    }
});

export default function ScrollBox({children}) {
    return (
        <CustomScroll paddingX={1} maxHeight={'100%'} overflow={'auto'}>
            {children}
        </CustomScroll>
    );
}