import {Box, ButtonBase, Dialog, DialogContent} from "@mui/material";
import {useState} from "react";
import {InfoIcon} from "../tools/MyIcon";

export default function Helper() {
    const [open, setOpen] = useState(false);

    return (
        <Box>
            <ButtonBase sx={{position: 'fixed', right: 10, bottom: 75, zIndex: 2}} onClick={() => setOpen(true)}>
                <InfoIcon fontSize={50}/>
            </ButtonBase>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent sx={{padding: 0}}>
                    <iframe
                        title={"Helper"}
                        src="https://docs.google.com/presentation/d/e/2PACX-1vRR492rZLO8_EYJyv8YwBcaj67wYNRj6BVte-49hzMbEC_ks5aWclEHMyfUfvjB2JGYOSKIqBPJrBif/embed?start=false&loop=false&delayms=60000"
                        frameBorder="0" width="380" height="250" allowFullScreen={true}/>
                </DialogContent>
            </Dialog>
        </Box>
    );
}