import React from "react";
import {Box} from "@mui/material";
import {generatePoints} from "../libs/helpers";

export default function Poly({
                                 r,
                                 text = '',
                                 icon = null,
                                 position = 'fixed',
                                 sides = 6,
                                 fill = 'transparent',
                                 stroke = 'transparent',
                                 left = 'auto',
                                 right = 'auto',
                                 top = 'auto',
                                 bottom = 'auto'}) {
    return (
        <Box sx={{position: position, zIndex: 0, left: left, bottom: bottom, right: right, top: top}}>
            <svg width={2*r} height={2*r}>
                <polyline points={generatePoints(sides, r)} fill={fill} strokeWidth={3} stroke={stroke} />
                {icon && icon(r)}
                <text x={r - (1.8 * text.length)} y={r+2} fill="white" fontSize={15} fontWeight={"bold"}>{text}</text>
            </svg>
        </Box>
    );
};