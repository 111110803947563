import * as ActionTypes from "./actionTypes";
import {post} from "../services/api";
import * as PATHS from "../config/paths";

export const fetchUser = () => (dispatch) => {
    return post(PATHS.USER).then(user => {
        dispatch({
            type: ActionTypes.FETCH_USER,
            payload: user,
        });
    }).catch(() => {
        localStorage.clear();
    });
}

export const fetchPlaces = () => (dispatch) => {
    return post(PATHS.PLACES).then(places => {
        dispatch({
            type: ActionTypes.FETCH_PLACES,
            payload: places,
        })
    }).catch(() => {
    });
}

export const fetchMeasures = () => (dispatch) => {
    return post(PATHS.MEASURES).then(measures => {
        dispatch({
            type: ActionTypes.FETCH_MEASURES,
            payload: measures,
        })
    }).catch(() => {
    });
}

export const fetchOffers = () => (dispatch) => {
    return post(PATHS.OFFERS).then(offers => {
        dispatch({
            type: ActionTypes.FETCH_OFFERS,
            payload: offers,
        })
    }).catch(() => {
    });
}

export const fetchSelectedOffer = (offer) => (dispatch) => {
    dispatch({
        type: ActionTypes.FETCH_OFFER,
        payload: offer,
    });
}

export const fetchSnackbar = (message, severity = 'success') => (dispatch) => {
    dispatch({
        type: ActionTypes.FETCH_SNACKBAR,
        payload: {
            severity: severity,
            message: message,
        },
    });
}

export const fetchNotification = (notification) => (dispatch) => {
    dispatch({
        type: ActionTypes.FETCH_NOTIFICATION,
        payload: notification
    });
}