import {Box, Link, Typography} from "@mui/material";
import {useState} from "react";
import PasswordField from "../tools/PasswordField";
import {put} from "../services/api";
import * as PATHS from "../config/paths";
import {useHistory} from "react-router-dom";
import MyPanelTitle from "../tools/MyPanelTitle";
import MyButton from "../tools/MyButton";
import {useDispatch} from "react-redux";
import {fetchSnackbar} from "../redux/actions";

const initialErrors = {
    password: [null],
};

export default function Security({changePage}) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(initialErrors);
    const history = useHistory();
    const dispatch = useDispatch();

    const handlePassword = (event) => {
        setLoading(true);
        setErrors(initialErrors);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        put(PATHS.PASSWORD, data).then(() => {
            history.replace(PATHS.HOME);
            setLoading(false);
            dispatch(fetchSnackbar("Datos actualizados con éxito."));
        }).catch(({response}) => {
            setLoading(false);
            setErrors({...response.data.errors});
        });
    }

    return (
        <Box sx={{p: 2}}>
            <Box paddingBottom={2}>
                <MyPanelTitle subtitle={"SEGURIDAD"} withLogo={false}/>
                <Link onClick={() => changePage(0)}>
                    <Typography paddingRight={5} textAlign={'right'} color={"white"} fontWeight={"bold"}>PERFIL</Typography>
                </Link>
            </Box>
            <Box component={"form"} onSubmit={handlePassword}>
                <PasswordField
                    name={"old_password"}
                    label={"Contraseña actual"}
                />
                <PasswordField
                    name={"password"}
                    label={"Contraseña"}
                    valid={errors.password[0] == null}
                    helperText={errors.password[0]}
                />
                <PasswordField
                    name={"password_confirmation"}
                    label={"Confirmar contraseña"}
                />
                <MyButton 
                    type="submit" 
                    loading={loading}
                    sx={{mt: 3, mb: 2}}
                >
                    GUARDAR
                </MyButton>
            </Box>
        </Box>
    );
}