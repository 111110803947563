import logo from '../images/logo.png';
import {Box, Stack, Typography} from "@mui/material";

export default function MyPanelTitle({title, subtitle, withLogo = true}) {
    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
            { withLogo && <Box marginRight={1}>
                <img src={logo} alt="Logo" width={100}/>
            </Box> }
            <Stack textAlign={"right"}>
                <Typography color={"#ffcf00"} fontSize={'20px'} lineHeight={'20px'} fontWeight={"bold"}>{title}</Typography>
                <Typography fontFamily={'Artegra'} color={"white"} fontSize={'24px'} lineHeight={'40px'} fontWeight={"bold"}>{subtitle}</Typography>
            </Stack>
        </Stack>
    );
}