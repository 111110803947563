export const styleInput = {
    '& label': {
        color: '#91908e',
    },
    '& label.Mui-focused, .MuiFormHelperText-root': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset, & fieldset, &:hover fieldset': {
            borderColor: 'white',
            borderRadius: 15,
            color: 'white',
        },
        '& input, textarea': {
            color: 'white',
        },
    },
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
        color: 'white',
    },
    '& .MuiSelect-select, .MuiSvgIcon-root': {
        color: 'white',
    }
};