import axios from "axios";
import {API_URL} from "../config/constants";

axios.interceptors.request.use(request => {
    let accessToken = localStorage.getItem('access_token');
    if (accessToken) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return request;
});

export async function post(path, params) {
    const response = await axios.post(API_URL + path, params);
    return response.data;
}

export async function put(path, params) {
    params.append('_method', 'PUT');
    const response = await axios.post(API_URL + path, params);
    return response.data;
}

export async function get(path, params) {
    const response = await axios.get(API_URL + path);
    return response.data
}
