export const HOME = "/";
export const USER = "/user";
export const PASSWORD = "/password";
export const ACCOUNT = "/account";
export const LOGOUT = "/logout";
export const PLACES = "/places";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const FORGET = "/forget";
export const RESET = "/reset";
export const ABOUT = "/about";
export const CONTRACTS = "/contracts";
export const CONTRACT_STATUSES = "/contract/statuses";
export const CONTRACT_TASK = "/contract/task";
export const CONTRACT_OFFER = "/contract/offer";
export const CONTRACT_BANK = "/contract/bank";
export const CONTRACT_COMMENT = "/contract/comment";
export const CONTRACT_COMMENTS = "/contract/comments";
export const RANKING_COMMENT = "/ranking/comment";
export const RANKING_COMMENTS = "/ranking/comments";
export const OFFERS = "/offers";
export const MEASURES = "/measures";
export const RANKING = "/ranking";
