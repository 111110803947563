export function authenticated() {
    return localStorage.getItem('access_token') != null;
}

export function saveToken(result) {
    localStorage.setItem('access_token', result.access_token);
    localStorage.setItem('refresh_token', result.refresh_token);
}

export function generatePoints(sides, r) {
    let points = [];
    for (let i = 1; i < sides + 2; i++) {
        points.push({
            y: r + Math.round(r * Math.sin((Math.PI / (sides / 2)) * i)),
            x: r + Math.round(r * Math.cos((Math.PI / (sides / 2)) * i))
        });
    }

    let pointsStr = "";
    points.forEach(val => {
        pointsStr += `${val.x},${val.y} `;
    });

    return pointsStr;
}