import {
    Box,
    Button,
    Chip,
    Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    List, ListItem, ListItemText,
    Stack,
    Typography
} from "@mui/material";
import * as DEFAULTS from "../config/defaults";
import {useState} from "react";
import MyPanel from "../tools/MyPanel";
import Header from "./Header";
import MyTitle from "../tools/MyTitle";
import {Room} from "@mui/icons-material";
import plane from '../images/plane.jpg';
import MyButton from "../tools/MyButton";
import * as PATHS from "../config/paths";
import {post} from "../services/api";
import {fetchOffers} from "../redux/actions";
import {useDispatch} from "react-redux";
import {useUser} from "../libs/hooks";
import {useHistory} from "react-router-dom";
import FilesCard from "../tools/FilesCard";
import {fetchSnackbar} from "../redux/actions";
import {TermsIcon} from "../tools/MyIcon";

const ConfirmationDialog = ({open, offer, handleClose, setLoading}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const acceptOffer = () => {
        handleClose();
        setLoading(true);
        post(`${PATHS.CONTRACT_OFFER}/${offer.id}`).then((contract) => {
            setLoading(false);
            dispatch(fetchOffers());
            dispatch(fetchSnackbar("Se generó con éxito el contrato."));
            history.push(`${PATHS.CONTRACTS}/${contract.id}?new=1`);
        }).catch((error) => {
            setLoading(false);
            dispatch(fetchSnackbar(error.response.data.message, "error"));
        })
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Seguro de tomar oferta?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
                <Button onClick={acceptOffer} autoFocus>
                    Acepto
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const ExpedientItem = ({index, expedient = DEFAULTS.EXPEDIENT}) => {
    return (
        <Box color={"white"} display={"flex"} alignItems={"center"} paddingBottom={1}>
            <Typography width={"100%"}>{expedient.description}</Typography>
            <Stack direction={"row"}>
                <Chip label={'Tipo'} sx={{color: 'white'}} onClick={() => window.open(expedient.type, '_blank')}/>
                <Chip label={'Ficha'} sx={{color: 'white'}} onClick={() => window.open(expedient.card, '_blank')}/>
            </Stack>
        </Box>
    );
}

export default function OfferDetail({offer = DEFAULTS.OFFER}) {
    const [openDialog, setOpenDialog] = useState(false);
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const [openCadastreDialog, setOpenCadastreDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useUser();
    const history = useHistory();
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <MyPanel
            backgroundImage={plane}
            modelType={3}
            support={offer.place.phone ? `https://api.whatsapp.com/send?phone=${offer.place.phone}` : null}
            helper={true}
            header={(
                <Box height={'100px'}>
                    <Header navButton={<div/>}/>
                </Box>
            )}
            content={(
                <Box color={'white'}>
                    <Dialog onClose={() => setOpenDownloadDialog(false)} open={openDownloadDialog}>
                        <DialogTitle>Seleccione para descargar:</DialogTitle>
                        <List sx={{ pt: 0 }}>
                            <ListItem button onClick={() => window.open(offer.place.manual, '_blank')}>
                                <ListItemText primary={'Manual de dibujo'} />
                            </ListItem>
                            <ListItem button onClick={() => window.open(offer.place.contract, '_blank')}>
                                <ListItemText primary={'Términos y Condiciones'} />
                            </ListItem>
                        </List>
                    </Dialog>
                    <Dialog onClose={() => setOpenCadastreDialog(false)} open={openCadastreDialog}>
                        <DialogTitle>Seleccione para descargar:</DialogTitle>
                        <List sx={{ pt: 0 }}>
                            <ListItem button onClick={() => window.open(offer.place.location_cadastre, '_blank')}>
                                <ListItemText primary={'Catastro de Localización'} />
                            </ListItem>
                            <ListItem button onClick={() => window.open(offer.place.ubication_cadastre, '_blank')}>
                                <ListItemText primary={'Catastro de Ubicación'} />
                            </ListItem>
                        </List>
                    </Dialog>
                    <ConfirmationDialog open={openDialog} offer={offer} handleClose={handleCloseDialog} setLoading={setLoading}/>
                    <MyTitle name={offer.name}/>
                    <Typography>{offer.description}</Typography>
                    <Typography>Cupos: {offer.slotText}</Typography>
                    <Stack direction={"row"} paddingY={2}>
                        <Box display={"flex"}>
                            <Room/>
                            <Typography>{offer.place.name}</Typography>
                        </Box>
                        <Typography marginLeft={'auto'}>{offer.formatCreated}</Typography>
                    </Stack>
                    <FilesCard
                        title={"Descarga de archivos:"}
                        previous={(
                            <Box textAlign={'right'} paddingBottom={1}>
                                <Chip label={'Catastro del  Sector'} sx={{color: 'white'}} onClick={() => setOpenCadastreDialog(true)}/>
                            </Box>
                        )}
                        items={offer.expedients}
                        component={(item, index) => {
                            return <ExpedientItem key={index} index={index} expedient={item}/>;
                        }}
                    />
                    {offer.enabled && <Box paddingY={2} textAlign={'right'}>
                        <MyButton
                            onClick={() => {
                                if (user) setOpenDialog(true)
                                else history.push(PATHS.LOGIN);
                            }}
                            color={"#e53b24"}
                            loading={loading}
                        >
                            ACEPTAR
                        </MyButton>
                    </Box>}
                    <Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0, minHeight: 56, backgroundColor: '#ffcf00'}}>
                        <Box sx={{marginLeft: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center', padding: 1}} onClick={() => setOpenDownloadDialog(true)}>
                            <TermsIcon fontSize={40}/>
                            <Typography sx={{paddingLeft: 2}}>Manual de Dibujo y<br/>Términos y Condiciones</Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        />
    );
}