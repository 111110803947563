import {Box} from "@mui/material";
import * as PATHS from "../config/paths";
import MyPanel from "../tools/MyPanel";
import Header from "./Header";
import MyTitle from "../tools/MyTitle";
import MyTextField from "../tools/MyTextField";
import MyButton from "../tools/MyButton";
import MyLink from "../tools/MyLink";

export default function Forget() {
    return (
        <MyPanel
            header={(
                <Box>
                    <Header/>
                    <Box paddingX={5}>
                        <MyTitle name={"RECUPERACION DE CUENTA"}/>
                        <span>Recupera tu cuenta con el correo que te registraste.</span>
                    </Box>
                </Box>
            )}
            content={(
                <Box textAlign={"center"}>
                    <Box component={"form"}>
                        <MyTextField
                            type={"email"}
                            id="email"
                            label="Correo"
                            name={"email"}
                        />
                        <MyButton
                            type="submit"
                            sx={{mt: 3, mb: 2}}
                        >
                            ENVIAR
                        </MyButton>
                    </Box>
                    <Box>
                        <MyLink to={PATHS.LOGIN}>
                            Recordé mi contraseña
                        </MyLink>
                    </Box>
                </Box>
            )}
        />
    );
}