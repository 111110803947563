import MyPanel from "../tools/MyPanel";
import {Box, Button, Paper, Typography} from "@mui/material";
import Header from "./Header";
import * as PATHS from "../config/paths";
import MyTitle from "../tools/MyTitle";
import {useCallback, useEffect, useState} from "react";
import {post} from "../services/api";
import FilterDialog from "../tools/FilterDialog";
import {FilterAlt} from "@mui/icons-material";
import MyCard from "../tools/MyCard";
import ContractDetail from "./ContractDetail";
import {useHistory, useRouteMatch} from "react-router-dom";
import ScrollBox from "../tools/ScrollBox";
import ContractsCake from "../tools/ContractsCake";

export default function ContractList() {
    const [contracts, setContracts] = useState([]);
    const [statusFilter, setStatusFilter] = useState(null);
    const [openFilterContract, setOpenFilterContract] = useState(false);
    const [statuses, setStatuses] = useState([{id: -1, name: 'TODOS'}]);
    const [pending, setPending] = useState(0);
    const [selectContract, setSelectContract] = useState(null);
    const history = useHistory();
    const match = useRouteMatch(`${PATHS.CONTRACTS}/:id`);

    const openContract = useCallback((contract) => {
        history.push(`${PATHS.CONTRACTS}/${contract.id}${history.location.search}`);
        setSelectContract(contract);
    }, [history]);

    useEffect(() => {
        let hasContract = false;
        if (match) {
            let id = parseInt(match.params.id);
            hasContract = (selectContract && (selectContract.id === id));
            if (!hasContract) {
                for (let cont of contracts) {
                    if (cont.id === id) {
                        hasContract = true;
                        openContract(cont);
                    }
                }
            }
        }
        if (!hasContract) setSelectContract(null);
    }, [contracts, match, selectContract, openContract]);

    useEffect(() => {
        post(PATHS.CONTRACT_STATUSES).then(data => {
            let res = statuses;
            for (let x in data) {
                res.push({id: parseInt(x), name: data[x]});
            }
            setStatuses(res);
            setStatusFilter(res[0]);
        }).catch(() => {
        })
        post(PATHS.CONTRACTS).then(contracts => {
            setContracts(contracts);
            setPending(contracts.filter(item => item.status === 1).length);
        }).catch(() => {
        })
    }, [statuses]);

    return (
        <Paper>
            {
                selectContract ?
                <ContractDetail contract={selectContract} setSelectContract={setSelectContract} /> :
                <MyPanel
                    modelType={4}
                    support={true}
                    helper={true}
                    header={(
                        <Box>
                            <Header navButton={(
                                <Box/>
                            )}/>
                            <Box paddingX={4} paddingBottom={2}>
                                <Box textAlign={"right"} marginRight={12}>
                                    <MyTitle label={"Resumen de mis"} name={"CONTRATOS"}/>
                                </Box>
                                <span>{pending} contrato(s) pendiente(s)</span>
                            </Box>
                        </Box>
                    )}
                    content={(
                        <Box height={'40vh'}>
                            <FilterDialog items={statuses} open={openFilterContract} setOpen={setOpenFilterContract} setSelect={setStatusFilter}/>
                            <Box marginTop={2} color={"white"} display={"flex"} alignItems={"center"}>
                                <Button style={{color: 'white'}} startIcon={<FilterAlt/>} onClick={() => setOpenFilterContract(true)}>
                                    <Typography>{statusFilter?.name}</Typography>
                                </Button>
                            </Box>
                            <ScrollBox padding={2}>
                                {contracts.filter((item) => statusFilter?.id === -1 || item.status === statusFilter?.id).map((item, index) => {
                                    return (
                                        <MyCard
                                            key={index}
                                            item={item}
                                            setSelected={(item) => openContract(item)}
                                            left={{
                                                primary: 'name',
                                                secondary: 'statusText'
                                            }}
                                            right={{
                                                secondary: 'formatCreated'
                                            }}
                                        />
                                    );
                                })}
                            </ScrollBox>
                            <Typography fontSize={14} color={'antiquewhite'}>Cada contrato es independiente y se culmina cuando pasa a estado "Aprobado"</Typography>
                            <ContractsCake contracts={contracts}/>
                        </Box>
                    )}
                />
            }
        </Paper>
    );
}