import {
    FETCH_MEASURES,
    FETCH_NOTIFICATION,
    FETCH_OFFER,
    FETCH_OFFERS,
    FETCH_PLACES,
    FETCH_SNACKBAR,
    FETCH_USER
} from "./actionTypes";

const initialState = {
    user: null,
    places: [],
    offers: [],
    measures: [],
    snackbar: {
        severity: 'success',
        message: null,
    },
    selectedOffer: null,
    notification: null,
}

export default function reducer(state = initialState, action) {
    let changes;
    switch (action.type) {
        case FETCH_USER:
            changes = {user: action.payload};
            break;
        case FETCH_PLACES:
            changes = {places: [{name: "TODOS"}].concat(action.payload)};
            break;
        case FETCH_MEASURES:
            let res = [];
            for (let x in action.payload) {
                res.push({id: parseInt(x), name: action.payload[x]});
            }
            changes = {measures: [{name: "TODOS"}].concat(res)};
            break;
        case FETCH_OFFERS:
            changes = {offers: action.payload};
            break;
        case FETCH_OFFER:
            changes = {selectedOffer: action.payload};
            break;
        case FETCH_SNACKBAR:
            changes = {snackbar: {...state.snackbar, ...action.payload}};
            break;
        case FETCH_NOTIFICATION:
            changes = {notification: action.payload};
            break;
        default:
            changes = {};
    }
    return {...state, ...changes};
}
