import {Box, Typography} from "@mui/material";
import Poly from "./Poly";
import {Fragment} from "react";
import {PolyButton} from "./PolyButton";
import Helper from "../components/Helper";
import {ChatIcon} from "./MyIcon";

const redColor = "#e53b24";
const yellowColor = "#ffcf00";

const TopRightDesign = () => {
    const radiusTop = 55;
    return (
        <Fragment>
            <Poly r={radiusTop} top={-15} right={10} stroke={yellowColor}/>
            <Poly r={radiusTop} top={-64} right={92} stroke={yellowColor}/>
            <Poly r={radiusTop} top={-64} right={-72} stroke={yellowColor}/>
            <Poly r={radiusTop} top={34} right={-72} stroke={yellowColor}/>
            <Poly r={radiusTop} top={130} right={-72} stroke={yellowColor}/>
        </Fragment>
    );
}

const Model1 = () => {
    return (
        <Fragment>
            <TopRightDesign/>

            <Poly r={50} bottom={360} left={-75} fill={yellowColor} />

            <Poly r={100} bottom={-50} left={-30} fill={redColor} />
            <Poly r={70} bottom={50} right={-50} fill={redColor}/>
        </Fragment>
    );
}

const Model4 = () => {
    return (
        <Fragment>
            <TopRightDesign/>

            <Poly r={50} bottom={360} left={-75} fill={yellowColor} />

            <Poly r={70} bottom={50} right={-50} fill={redColor}/>
        </Fragment>
    );
}

const Model2 = () => {
    const radiusTop = 55;
    return (
        <Fragment>
            <Poly r={radiusTop} bottom={85} right={10} stroke={yellowColor}/>
            <Poly r={radiusTop} bottom={-10} right={10} stroke={yellowColor}/>
            <Poly r={radiusTop} bottom={-59} right={92} stroke={yellowColor}/>
            <Poly r={radiusTop} bottom={-59} right={-72} stroke={yellowColor}/>
            <Poly r={radiusTop} bottom={39} right={-72} stroke={yellowColor}/>
            <Poly r={radiusTop} bottom={135} right={-72} stroke={yellowColor}/>

            <Poly r={50} bottom={360} left={-75} fill={yellowColor} />

            <Poly r={40} top={150} right={-10} fill={redColor}/>
        </Fragment>
    );
}

const Model3 = () => {
    return (
        <Fragment>
            <TopRightDesign/>
        </Fragment>
    );
}

export default function MyPanel({header, content, modelType = 1, backgroundImage = null, support = null, helper = false, mobileNavigation = null}) {


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: `calc(100vh - ${mobileNavigation ? 58 : 0}px)`,
        }}>
            {backgroundImage && <Box
                sx={{
                    top: 0,
                    bottom: '75%',
                    left: 0,
                    right: 0,
                    position: 'absolute',
                    zIndex: 1,
                }}
            ><img src={backgroundImage} width={'100%'} height={'100%'} alt={''}/></Box>}
            <Box sx={{
                paddingX: 4,
                paddingBottom: modelType === 1 ? 4 : 0,
                position: 'relative',
                zIndex: 3,
            }}>
                {header}
            </Box>
            <Box sx={{
                background: '#2b2a28',
                height: '100%',
                borderTopLeftRadius: 50,
                borderTopRightRadius: 50,
                paddingTop: '40px',
                paddingX: 6,
                zIndex: 2,
                overflow: 'auto',
                boxShadow: '0px -10px 25px grey'
            }}>
                {(modelType === 1 ? <Model1/> : (modelType === 2 ? <Model2/> : (modelType === 3 ? <Model3/> : <Model4/>)))}
                <Box position={'relative'} height={'100%'}>{content}</Box>
            </Box>
            { support && (
                <Box sx={{position: 'fixed', left: -30, bottom: -30, zIndex: 2}}>
                    <PolyButton radius={80} fill={redColor} onClick={() => window.open(support, '_blank')}>
                        <Box sx={{textAlign: 'center', color: 'white'}}>
                            <ChatIcon fontSize={35}/>
                            <Typography sx={{fontSize: 13}}>Chatea con<br/> Nosotros</Typography>
                        </Box>
                    </PolyButton>
                </Box>
            )}
            { helper && <Helper/> }
            {mobileNavigation}
        </Box>
    );
}