import * as React from 'react';
import {Redirect, Route} from "react-router-dom";
import * as PATHS from "../config/paths";
import {authenticated} from "../libs/helpers";

class GuestRoute extends Route {
    render() {
        return (
            <Route { ...this.props } render={() => {
                return !authenticated()
                    ? <this.props.view/>
                    : <Redirect to={PATHS.HOME} />
            }}/>
        );
    }
}

export default GuestRoute;
