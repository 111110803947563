export const OFFER = {
    id: undefined,
    name: '',
    description: '',
    expedients: [],
    price: 0.0,
    contract: '',
    manual: '',
    cadastre: '',
    placeId: 0,
    formatCreated: '',
    slotText: '',
    created: '',
    receipt: '',
    place: {
        name: '',
        phone: null,
        location_cadastre: '',
        ubication_cadastre: '',
    },
    bankAccount: {
        number: '',
        holder: '',
        dni: '',
    },
    enabled: false,
};

export const EXPEDIENT = {
    id: undefined,
    description: '',
    price: 0.0,
    type: '',
    card: '',
}

export const CONTRACT = {
    id: undefined,
    tasks: [],
}

export const TASk = {
    id: undefined,
    description: '',
    statusText: '',
    status: 0,
}
