import {Box, IconButton} from "@mui/material";
import {ArrowBackIosNew} from "@mui/icons-material";
import {useHistory} from "react-router-dom";

export default function Back({onClick = null}) {
    const history = useHistory();
    return (
        <Box>
            <IconButton onClick={() => {
                if (onClick) onClick();
                history.goBack();
            }}>
                <ArrowBackIosNew/>
            </IconButton>
        </Box>
    );
}