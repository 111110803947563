import {Box} from "@mui/material";
import {post} from "../services/api";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import * as PATHS from "../config/paths";
import PasswordField from "../tools/PasswordField";
import {useDispatch} from "react-redux";
import {fetchUser} from "../redux/actions";
import {saveToken} from "../libs/helpers";
import MyPanel from "../tools/MyPanel";
import Header from "./Header";
import MyTitle from "../tools/MyTitle";
import MyTextField from "../tools/MyTextField";
import MyLink from "../tools/MyLink";
import MyButton from "../tools/MyButton";

export default function Login() {
    const [valid, isValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleLogin = (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        post(PATHS.LOGIN, data).then(result => {
            saveToken(result);
            dispatch(fetchUser());
            setLoading(false);
            history.replace(PATHS.HOME);
        }).catch(() => {
            isValid(false);
            setLoading(false);
        });
    };

    return (
        <MyPanel
            header={(
                <Box>
                    <Header/>
                    <Box paddingX={5}>
                        <MyTitle name={"INICIA SESION"}/>
                        <span>Accede a nuestra ofertas de módulos de Vivienda de Interés Social - VIS</span>
                    </Box>
                </Box>
            )}
            content={(
                <Box>
                    <Box component={"form"} onSubmit={handleLogin} textAlign={"center"}>
                        <MyTextField
                            type={"email"}
                            id="email"
                            label="Correo"
                            name={"email"}
                            error={!valid}
                            helperText={valid ? "" : "Credenciales incorrectas"}
                        />
                        <PasswordField
                            name={"password"}
                            label={"Contraseña"}
                            valid={valid}
                        />
                        <Box textAlign={"right"} paddingY={2}>
                            <MyLink to={PATHS.FORGET}>
                                Olvidé mi contraseña
                            </MyLink>
                        </Box>
                        <MyButton
                            type="submit"
                            loading={loading}
                        >
                            ACCEDER
                        </MyButton>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} flexDirection={"column"} paddingTop={4}>
                        <MyLink to={PATHS.REGISTER}>
                            ¿No tienes cuenta?
                        </MyLink>
                    </Box>
                </Box>
            )}
        />
    );
}