import {Box, Typography} from "@mui/material";
import {Chart} from "react-google-charts";
import {STATUSES_CONTRACT} from "../config/constants";

export default function ContractsCake({contracts}) {
    const total = contracts.length;
    const hired = contracts.filter(c => parseInt(c.status) === STATUSES_CONTRACT.HIRED);
    const ptje = 100.0 * (hired.length / total);
    return (
        <Box>
            <Box sx={{
                position: 'absolute',
                width: '100%',
                textAlign: 'center',
                marginTop: '11vh',
            }}>
                <Typography fontSize={22} color={'#ffcf00'}>{ptje} %</Typography>
            </Box>
            <Chart
                width={'100%'}
                height={'25vh'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                    ['Contratos', 'Porcentaje'],
                    ['', hired.length / total],
                    ['', (total - hired.length) / total],
                ]}
                options={{
                    legend: 'none',
                    pieSliceText: 'label',
                    pieStartAngle: 0,
                    backgroundColor: 'transparent',
                    slices: {
                        0: { color: '#ffcf00' },
                        1: { color: 'white' },
                    },
                    pieHole: 0.7,
                }}
                rootProps={{ 'data-testid': '3' }}
            />
        </Box>
    );
}