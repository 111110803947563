import {useSelector} from "react-redux";

export function useUser() {
    return useSelector(state => state.user);
}

export function useSnackbar() {
    return useSelector(state => state.snackbar);
}

export function usePlaces() {
    return useSelector(state => state.places);
}

export function useMeasures() {
    return useSelector(state => state.measures);
}

export function useOffers() {
    return useSelector(state => state.offers);
}

export function useSelectedOffer() {
    return useSelector(state => state.selectedOffer);
}

export function useNotification() {
    return useSelector(state => state.notification);
}