import {Box, Typography} from "@mui/material";
import InputButtonField from "../tools/InputButtonField";
import {Send} from "@mui/icons-material";
import {useEffect, useRef, useState} from "react";
import {post} from "../services/api";
import ScrollBox from "../tools/ScrollBox";
import {NOTIFICATION} from "../config/constants";
import {useNotification} from "../libs/hooks";

export default function CommentList({height, hasForm, urlList, urlStore, label}) {
    const notification = useNotification();
    const [comments, setComments] = useState([]);

    const CommentForm = () => {
        const inputRef = useRef(null);

        const handleComment = (e) => {
            e.preventDefault();
            const data = new FormData(e.currentTarget);
            post(urlStore, data).then(comment => {
                setComments([comment].concat(comments));
            }).catch(() => {});
        }

        return (
            <Box component={'form'} onSubmit={handleComment}>
                <input type={'submit'} hidden ref={inputRef}/>
                <InputButtonField
                    type={'text'}
                    name={'message'}
                    label={label}
                    multiline
                    iconButton={<Send/>}
                    onClick={() => inputRef.current.click()}
                />
            </Box>
        );
    }

    useEffect(() => {
        switch (notification?.model) {
            case NOTIFICATION.COMMENT:
                setComments(comments => [notification.response].concat(comments));
                break;
            default:
        }
    }, [notification, setComments]);

    useEffect(() => {
        post(urlList).then(data => {
            setComments(data);
        }).catch(() => {});
    }, [urlList]);

    return (
        <Box height={height} display={'flex'} flexDirection={'column'}>
            {hasForm && <CommentForm/>}
            <ScrollBox>
                <Box paddingTop={hasForm ? 1 : 0} paddingBottom={5}>
                {comments.map((comment, index) => (
                    <Box key={index} sx={{color: 'white', pb: 1}}>
                        <Box display={'flex'}>
                            <Typography width={'100%'} fontWeight={'bold'}>{comment.username}</Typography>
                            <Typography>{comment.formatCreated}</Typography>
                        </Box>
                        <Typography sx={{whiteSpace: 'pre-wrap'}}>{comment.message}</Typography>
                    </Box>
                ))}
                </Box>
            </ScrollBox>
        </Box>
    );
}