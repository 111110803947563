import {MyFormControl} from "./MyFormControl";
import {FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";

export default function InputButtonField({label, name, type, iconButton, onClick, helperText, value = '', valid = true, multiline = false}) {
    let props = {};
    if (value.length) {
        props.value = value;
    }
    return (
        <MyFormControl variant="outlined" margin={"dense"} fullWidth error={!valid}>
            <InputLabel htmlFor="outlined-adornment">{label}</InputLabel>
            <OutlinedInput
                {...props}
                type={type}
                name={name}
                required
                multiline={multiline}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle input visibility"
                            onClick={onClick}
                            edge="end"
                        >
                            {iconButton}
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
            />
            <FormHelperText error={!valid}>{helperText}</FormHelperText>
        </MyFormControl>
    );
}