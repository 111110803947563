import * as PATHS from "./paths";
import {AttachMoney, BarChart} from "@mui/icons-material";
import {AssociatedIcon} from "../tools/MyIcon";

export const options = [
    {
        path: PATHS.CONTRACTS,
        label: "Mis\nContratos",
        icon: ({fontSize = 25}) => <AttachMoney sx={{fontSize: fontSize}}/>,
        public: false,
    },
    {
        path: PATHS.RANKING,
        label: "Ranking",
        icon: ({fontSize = 25}) => <BarChart sx={{fontSize: fontSize, color: 'white'}}/>,
        public: true,
    },
    {
        path: PATHS.ABOUT,
        label: "Asociados",
        icon: ({fontSize = 15}) => <AssociatedIcon fontSize={fontSize}/>,
        public: true,
    },
];
