import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";
import InputButtonField from "./InputButtonField";

export default function PasswordField({valid = true, name, label, helperText = ''}) {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <InputButtonField
            type={showPassword ? 'text' : 'password'}
            name={name}
            iconButton={showPassword ? <VisibilityOff /> : <Visibility />}
            valid={valid}
            helperText={helperText}
            label={label}
            onClick={() => setShowPassword(!showPassword)}
        />
    );
}