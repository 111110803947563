import Pusher from "pusher-js";
import Echo from "laravel-echo";

export default function startPusher(user, callback) {
    Pusher.logToConsole = process.env.NODE_END !== 'production';

    const echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_KEY,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
        forceTLS: true,
        authEndpoint: process.env.REACT_APP_API_URL + "/broadcasting/auth",
        auth: {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            }
        }
    });

    echo.private(`App.User.${user.id}`)
        .notification((notification) => {
            callback(notification);
        });
}
