import {styled} from "@mui/styles";
import LoadingButton from '@mui/lab/LoadingButton';
import {Typography} from "@mui/material";

const ColorButton = styled(LoadingButton)(({ theme , mycolor}) => ({
    color: 'white',
    backgroundColor: mycolor,
    '&:hover': {
        backgroundColor: mycolor,
    },
    '&.Mui-disabled': {
        backgroundColor: mycolor,
    },
    fontWeight: 'bold',
    borderRadius: 10,
    paddingX: 3,
}));

export default function MyButton({type, sx, children, color = '#ffcf00', onClick, loading = false}) {
    return (
        <ColorButton
            loading={loading}
            startIcon={<div />}
            type={type}
            variant="contained"
            mycolor={color}
            onClick={onClick}
            sx={sx}
        >
            <Typography fontFamily={'Artegra'}>{children}</Typography>
        </ColorButton>
    );
}