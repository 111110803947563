import {styled} from "@mui/styles";
import {Box} from "@mui/material";

export const TrapezeBox = styled(Box)(({theme, bg, width}) => ({
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '6vh',
    display: 'flex',
    borderRight: '30px solid transparent',
    borderLeft: '30px solid transparent',
    borderBottom: '70px solid',
    borderBottomColor: bg,
}));

