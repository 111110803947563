import {useCallback, useEffect, useState} from "react";
import {Box, Button, Paper, Typography} from "@mui/material";
import OfferDetail from "./OfferDetail";
import {FilterAlt} from "@mui/icons-material";
import {useMeasures, useOffers, usePlaces, useSelectedOffer} from "../libs/hooks";
import MyPanel from "../tools/MyPanel";
import Header from "./Header";
import MyPanelTitle from "../tools/MyPanelTitle";
import MobileNavigation from "./MobileNavigation";
import FilterDialog from "../tools/FilterDialog";
import MyCard from "../tools/MyCard";
import ScrollBox from "../tools/ScrollBox";
import {useHistory, useRouteMatch} from "react-router-dom";
import * as PATHS from "../config/paths";
import {useDispatch} from "react-redux";
import {fetchSelectedOffer} from "../redux/actions";

export default function OfferList() {
    const [openFilterPlace, setOpenFilterPlace] = useState(false);
    const [selectPlace, setSelectPlace] = useState(null);
    const [openFilterMeasured, setOpenFilterMeasured] = useState(false);
    const [selMeasured, setSelMeasured] = useState(null);
    const places = usePlaces();
    const measures = useMeasures();
    const offers = useOffers();
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedOffer = useSelectedOffer();
    const match = useRouteMatch(`${PATHS.OFFERS}/:id`);

    const openOffer = useCallback((offer) => {
        history.push(`${PATHS.OFFERS}/${offer.id}`);
        dispatch(fetchSelectedOffer(offer));
    }, [history, dispatch]);

    useEffect(() => {
        let hasOffer = false;
        if (match) {
            let id = parseInt(match.params.id);
            hasOffer = (selectedOffer && selectedOffer.id === id);
            if (!hasOffer) {
                for (let off of offers) {
                    if (off.id === id) {
                        hasOffer = true;
                        openOffer(off);
                    }
                }
            }
        }
        if (!hasOffer) dispatch(fetchSelectedOffer(null));
    }, [offers, match, selectedOffer, openOffer, dispatch]);

    useEffect(() => {
        if  (places.length) {
            setSelectPlace(places[0]);
        }
        if (measures.length) {
            setSelMeasured(measures[0]);
        }
    }, [places, measures]);

    return (
        <Paper>
            {
                selectedOffer ?
                <OfferDetail offer={selectedOffer}/> :
                <MyPanel
                    helper={true}
                    modelType={2}
                    header={(
                        <Header showHome={true}/>
                    )}
                    content={(
                        <Box display={'flex'} flexDirection={'column'} height={'100%'}>
                            <FilterDialog items={places} open={openFilterPlace} setOpen={setOpenFilterPlace} setSelect={setSelectPlace}/>
                            <FilterDialog items={measures} open={openFilterMeasured} setOpen={setOpenFilterMeasured} setSelect={setSelMeasured}/>
                            <MyPanelTitle title={"Nuestras"} subtitle={"OFERTAS"}/>
                            <Box marginTop={2} color={"white"} display={"flex"} alignItems={"center"}>
                                <Box sx={{width: '100%'}}>
                                    <Button style={{color: 'white'}} startIcon={<FilterAlt/>} onClick={() => setOpenFilterPlace(true)}>
                                        <Typography>{selectPlace?.name}</Typography>
                                    </Button>
                                </Box>
                                <Box sx={{width: '100%'}}>
                                    <Button style={{color: 'white'}} startIcon={<FilterAlt/>} onClick={() => setOpenFilterMeasured(true)}>
                                        <Typography>{selMeasured?.name}</Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <ScrollBox>
                                {offers.filter((item) => (((item.place.id === selectPlace?.id) || (selectPlace?.id === undefined)) && ((item.measured === selMeasured?.id) || (selMeasured?.id === undefined))))
                                    .map((item, index) => { return (
                                        <MyCard
                                            key={index}
                                            item={item}
                                            setSelected={openOffer}
                                            left={{
                                                primary: 'name',
                                                secondary: 'tag'
                                            }}
                                            right={{
                                                primary: 'formatPrice',
                                                secondary: 'formatCreated'
                                            }}
                                        />
                                    );
                                })}
                            </ScrollBox>
                        </Box>
                    )}
                    mobileNavigation={<MobileNavigation/>}
                />
            }
        </Paper>
    );
}