import {Box, Button, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import * as PATHS from "../config/paths";

export default function Splash({setShow}) {
    const [showOptions, setShowOptions] = useState(false);
    const history = useHistory();

    const WhiteBorderButton = ({children, measured}) => {
        return (
            <Button variant={'outlined'} sx={{
                color: 'white',
                borderColor: 'white',
                borderWidth: 2,
                width: 100,
                padding: 2,
                borderRadius: 5,
                '&:hover': {
                    borderColor: 'white',
                },
            }} onClick={() => {
                history.push(`${PATHS.REGISTER}?m=${measured}`);
                setShow(false);
            }}>
                {children}
            </Button>
        );
    }

    useEffect(() => {
        setTimeout(() => {
            setShowOptions(true);
            localStorage.setItem('show_splash', '1');
        }, 11000);
    }, []);

    return (
        <Box sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
            backgroundColor: 'black',
        }}>
            <video src={'./splash/intro.mp4'} muted autoPlay width={'100%'} height={'100%'}/>
            { showOptions && <Box sx={{
                position: 'absolute',
                bottom: '15%',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
            }}>
                <Stack direction={'row'} spacing={2}>
                    <WhiteBorderButton measured={35}>
                        <Typography>35 m2</Typography>
                    </WhiteBorderButton>
                    <WhiteBorderButton measured={40}>
                        <Typography>40 m2</Typography>
                    </WhiteBorderButton>
                </Stack>
            </Box> }
        </Box>
    );
}