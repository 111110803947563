export const API_URL = process.env.REACT_APP_API_URL;
export const STATUSES_TASK = {
    PENDING: 10,
    COMPLETE: 20,
    APPROVED: 30,
};
export const STATUSES_CONTRACT = {
    CANCELED: 0,
    PENDING: 1,
    EVALUATING: 2,
    HIRED: 3,
    INCOMPLETE: 4,
    COMPLETE: 5,
};

export const NOTIFICATION = {
    GLOBAL: 100,
    CONTRACT: 101,
    COMMENT: 102,
};