import {Link as RouterLink} from "react-router-dom";
import {Link, Typography} from "@mui/material";
import * as PATHS from "../config/paths";

export default function MyLink({to = PATHS.HOME, children, onClick}) {
    return (
        <Link sx={{color: '#e53b24', textDecoration: 'none'}} onClick={onClick} fontSize={16} component={RouterLink} to={to} variant="body2">
            <Typography whiteSpace={'pre'}>{children}</Typography>
        </Link>
    );
}