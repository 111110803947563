import {ButtonUnstyled, buttonUnstyledClasses} from "@mui/material";
import {styled} from "@mui/system";
import {generatePoints} from "../libs/helpers";
import {forwardRef} from "react";

const ButtonRoot = forwardRef((props, ref) => {
    const { children, radius, ...other } = props;
    let sides = 6;
    let r = radius;
    return (
        <svg width={r*2} height={r*2} {...other} ref={ref}>
            <polygon points={generatePoints(sides, r)} className="bg"/>
            <foreignObject x="0" y="0" width={r*2} height={r*2}>
                <div className="content">{children}</div>
            </foreignObject>
        </svg>
    );
});

const CustomButtonRoot = styled(ButtonRoot)(({ theme, border, fill = 'transparent' }) => `
  overflow: visible;
  cursor: pointer;
  --main-color: ${border};
  --hover-color: ${fill};
  --active-color: ${
        theme.palette.mode === 'light'
            ? 'rgba(25,118,210,0.12)'
            : 'rgba(144,202,249,0.24)'
    };

  & polygon {
    fill: ${fill};
    transition: all 800ms ease;
    pointer-events: none;
  }
  
  & .bg {
    stroke: var(--main-color);
    stroke-width: 2;
    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.1));
    fill: ${fill};
  }

  &:hover,
  &.${buttonUnstyledClasses.focusVisible} {
    .bg {
      fill: var(--hover-color);
    }
  }

  &:focus,
  &.${buttonUnstyledClasses.focusVisible} {
    outline: none;
  }

  &.${buttonUnstyledClasses.active} { 
    & .bg {
      fill: var(--active-color);
      transition: fill 300ms ease-out;
    }
  }

  & foreignObject {
    pointer-events: none;

    & .content {
      font-family: Helvetica, Inter, Arial, sans-serif;
      font-size: 14px;
      font-weight: 200;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--main-color);
      text-transform: uppercase;
    }

    & svg {
      margin: 0 5px;
    }
  }`,
);

export const PolyButton = forwardRef((props, ref) => {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} ref={ref} />;
});