import {Avatar, Box, Button, Stack, Typography} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MyPanel from "../tools/MyPanel";
import Header from "./Header";
import MyPanelTitle from "../tools/MyPanelTitle";
import MobileNavigation from "./MobileNavigation";
import ant from '../images/companies/ANT.png';
import antara from '../images/companies/ANTARA.png';
import antark from '../images/companies/ANTARK.png';
import arles from '../images/companies/ARLES.png';
import arqpartners from '../images/companies/ARQPARTNERS.png';
import arqtech from '../images/companies/ARQTECH.png';
import arqtiva from '../images/companies/ARQTIVA.png';
import atica from '../images/companies/ATICA.png';
import atk from '../images/companies/ATK.png';
import {useState} from "react";
import {HexButton} from "../tools/HexButton";

const companies = [
    {
        name: 'ANT',
        src: ant,
        socials: [
            {
                icon: FacebookIcon,
                url: '/',
            },
            {
                icon: InstagramIcon,
                url: '/',
            },
            {
                icon: LinkedInIcon,
                url: '/',
            },
        ],
    },
    {
        name: 'ANTARA',
        src: antara,
        socials: [
            {
                icon: FacebookIcon,
                url: 'https://www.facebook.com/antara.constructora/',
            },
            {
                icon: InstagramIcon,
                url: 'https://www.instagram.com/antara.constructora/',
            },
        ],
    },
    {
        name: 'ARLES',
        src: arles,
        socials: [
            {
                icon: FacebookIcon,
                url: 'https://www.facebook.com/arles.constructora.5/',
            },
            {
                icon: InstagramIcon,
                url: 'https://www.instagram.com/constructora_arles/',
            },
        ],
    },
    {
        name: 'ARQPARTNERS',
        src: arqpartners,
        socials: [
            {
                icon: FacebookIcon,
                url: 'https://www.facebook.com/arqpartners.constructora/',
            },
            {
                icon: InstagramIcon,
                url: 'https://www.instagram.com/arqpartners.constructora/',
            },
        ],
    },
    {
        name: 'ANTARK',
        src: antark,
        socials: [
            {
                icon: FacebookIcon,
                url: 'https://www.facebook.com/antark.constructora.5',
            },
            {
                icon: InstagramIcon,
                url: 'https://www.instagram.com/antark.constructora/',
            },
        ],
    },
    {
        name: 'ARQTECH',
        src: arqtech,
        socials: [
            {
                icon: FacebookIcon,
                url: 'https://www.facebook.com/arqtech.constructora.1/',
            },
            {
                icon: InstagramIcon,
                url: 'https://www.instagram.com/constructora.arqtech/',
            },
        ],
    },
    {
        name: 'ARQTIVA',
        src: arqtiva,
        socials: [
            {
                icon: FacebookIcon,
                url: 'https://www.facebook.com/arqtiva.constructora/',
            },
            {
                icon: InstagramIcon,
                url: 'https://www.instagram.com/arqtiva.constructora/',
            },
        ],
    },
    {
        name: 'ATICA',
        src: atica,
        socials: [
            {
                icon: FacebookIcon,
                url: 'https://www.facebook.com/atica.constructora/',
            },
            {
                icon: InstagramIcon,
                url: 'https://www.instagram.com/atica.constructora/',
            },
        ],
    },
    {
        name: 'ATK',
        src: atk,
        socials: [
            {
                icon: FacebookIcon,
                url: 'https://www.facebook.com/atk.constructora/',
            },
            {
                icon: InstagramIcon,
                url: 'https://www.instagram.com/constructora.atk/',
            },
        ],
    },
];

export default function About() {
    const [selCompany, setSelCompany] = useState(companies[4]);
    return (
        <MyPanel
            modelType={2}
            header={(
                <Header showHome={true}/>
            )}
            content={(
                <Box>
                    <MyPanelTitle title={"Empresas"} subtitle={"ASOCIADAS"}/>
                    <Box sx={{ p: 2, color: 'white', }}>
                        <Typography fontWeight={"bold"} textAlign={"center"}>Somos un grupo de empresas que promueven los proyectos sociales en el norte del pais.</Typography>

                        <Box paddingTop={2} textAlign={'center'}>
                            {companies.map((item, index) => (
                                <HexButton key={index} sx={{margin: 1}} onClick={() => setSelCompany(item)}>
                                    <Avatar alt={item.name} sx={{width: 50, height: 50}} src={item.src}/>
                                </HexButton>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 1, mt:3, p:{md:5, xs:3} }} >
                            Sigue a {selCompany.name} en:
                            <Stack direction={'row'} spacing={2} textAlign="center" sx={{p:3 }}>
                                {selCompany.socials.map((item, index) => (
                                    <Button key={index} fullWidth variant="text" href={item.url}>
                                        <item.icon/>
                                    </Button>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            )}
            mobileNavigation={<MobileNavigation/>}
        />
    );
}