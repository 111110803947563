import {Box, TextField} from "@mui/material";
import {styled} from "@mui/styles";
import {styleInput} from "../config/styles";

const CssTextField = styled(TextField)(styleInput);

export default function MyTextField({
                                        name,
                                        label,
                                        type = "text",
                                        defaultValue = '',
                                        value = undefined,
                                        required = true,
                                        placeholder = '',
                                        inputProps = {},
                                        InputProps = {},
                                        error = false,
                                        helperText = '',
                                        onChange = null,
                                    }) {
    const props = {
        name: name,
        label: label,
        autoComplete: "off",
        type: type,
        fullWidth: true,
        margin: "dense",
        required: required,
        placeholder: placeholder,
        InputProps: InputProps,
        inputProps: inputProps,
        onChange: onChange,
        error: error,
        helperText: helperText,
    };
    if (value !== undefined) props.value = value;
    else props.defaultValue = defaultValue;
    return (
        <Box width={'100%'}>
            <CssTextField {...props}/>
        </Box>
    );
}