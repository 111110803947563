import {Avatar, Box, IconButton, Stack} from "@mui/material";
import {Link, useHistory} from "react-router-dom";
import * as PATHS from "../config/paths";
import {useUser} from "../libs/hooks";
import Back from "./Back";
import {post} from "../services/api";
import * as ActionTypes from "../redux/actionTypes";
import {useDispatch} from "react-redux";
import MyLink from "../tools/MyLink";
import {HomeIcon} from "../tools/MyIcon";

export default function Header({navButton = null, onBack = null, showHome = false}) {
    const user = useUser();
    const history = useHistory();
    const dispatch = useDispatch();

    const sizePhoto = '55px';

    const toggleAuth = () => {
        if (history.location.pathname === PATHS.ACCOUNT) {
            return PATHS.HOME;
        }
        if (history.location.pathname === PATHS.LOGIN) {
            return PATHS.HOME;
        }
        return user ? PATHS.ACCOUNT : PATHS.LOGIN;
    }

    const handleLogout = (event) => {
        event.preventDefault();
        post(PATHS.LOGOUT).then(() => {
            localStorage.clear();
            dispatch({
                type: ActionTypes.FETCH_USER,
                payload: null,
            });
            history.replace(PATHS.HOME);
        }).catch(() => {
        });
    };

    return (
        <Stack direction={"row"} alignItems={'center'} paddingY={1}>
            <Box width={'100%'}>
                {
                    showHome ? <MyLink to={PATHS.HOME}><HomeIcon fontSize={40}/></MyLink> :
                    ((history.location.pathname !== PATHS.HOME || onBack) ?
                    <Back onClick={onBack}/> : (user ? <MyLink onClick={handleLogout}>Cerrar sesión</MyLink> : <MyLink to={PATHS.LOGIN}>Iniciar sesión</MyLink>))
                }
            </Box>
            { navButton }
            { !navButton && (user ? (history.location.pathname === PATHS.ACCOUNT ?
                <MyLink onClick={handleLogout}>Cerrar sesión</MyLink> : <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                component={Link}
                to={toggleAuth}
                >
                    <Avatar sx={{ width: sizePhoto, height: sizePhoto }} alt="Remy Sharp" src={user.photo} />
                </IconButton>) : (history.location.pathname === PATHS.HOME && <MyLink to={PATHS.LOGIN}>Iniciar sesión</MyLink>
            ))}
        </Stack>
    );
}