import './App.css';
import {Route, Switch, useHistory} from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import * as PATHS from "./config/paths";
import GuestRoute from "./tools/GuestRoute";
import OfferList from "./components/OfferList";
import {useDispatch} from "react-redux";
import {fetchMeasures, fetchNotification, fetchOffers, fetchPlaces, fetchSnackbar, fetchUser} from "./redux/actions";
import {authenticated} from "./libs/helpers";
import DrawerNavigation from "./components/DrawerNavigation";
import {Box} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import Account from "./components/Account";
import About from "./components/About";
import Forget from "./components/Forget";
import Reset from "./components/Reset";
import ContractList from "./components/ContractList";
import Ranking from "./components/Ranking";
import AuthRoute from "./tools/AuthRoute";
import Splash from "./components/Splash";
import MySnackbar from "./tools/MySnackbar";
import {useUser} from "./libs/hooks";
import startPusher from "./libs/pusher";

const drawerWidth = 250;

function App() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useUser();

    const [showSplash, setShowSplash] = useState(() => {
        let show = true;
        show &= (history.location.pathname === PATHS.HOME);
        show &= !authenticated();
        return show;
    });

    useEffect(() => {
        if (user) {
            startPusher(user, (notification) => {
                document.getElementById('alert').play();
                dispatch(fetchSnackbar(notification.message, 'info'));
                dispatch(fetchNotification(notification));
            });
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (authenticated()) {
            dispatch(fetchUser());
        }
        dispatch(fetchPlaces());
        dispatch(fetchMeasures());
        dispatch(fetchOffers());
    }, [dispatch]);

    return (
        <Fragment>
            {showSplash ?
            <Splash setShow={setShowSplash}/> :
            <Fragment>
                <MySnackbar/>
                <DrawerNavigation drawerWidth={drawerWidth}/>
                <Box sx={{
                    marginLeft: {
                        xs: 0,
                        md: `${drawerWidth}px`,
                    },
                    width: {
                        xs: '100%',
                        md: `calc(100% - ${drawerWidth}px)`,
                    },
                }}>
                    <Switch>
                        <GuestRoute view={Login} path={PATHS.LOGIN}/>
                        <GuestRoute view={Register} path={PATHS.REGISTER}/>
                        <GuestRoute view={Forget} path={PATHS.FORGET}/>
                        <GuestRoute view={Reset} path={PATHS.RESET}/>
                        <AuthRoute view={Account} path={PATHS.ACCOUNT}/>
                        <Route component={About} path={PATHS.ABOUT}/>
                        <AuthRoute view={ContractList} path={[PATHS.CONTRACTS, `${PATHS.CONTRACTS}/:id`]}/>
                        <Route component={Ranking} path={PATHS.RANKING}/>
                        <Route component={OfferList} path={[PATHS.HOME, `${PATHS.OFFERS}/:id`]}/>
                    </Switch>
                </Box>
            </Fragment>}
        </Fragment>
    );
}

export default App;
