import {Box} from "@mui/material";
import { post } from "../services/api";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import * as PATHS from "../config/paths";
import PasswordField from "../tools/PasswordField";
import {useDispatch} from "react-redux";
import {fetchSnackbar, fetchUser} from "../redux/actions";
import {saveToken} from "../libs/helpers";
import MyPanel from "../tools/MyPanel";
import Header from "./Header";
import MyTitle from "../tools/MyTitle";
import MyTextField from "../tools/MyTextField";
import MyButton from "../tools/MyButton";
import MyLink from "../tools/MyLink";

const initialErrors = {
    password: [null],
};

export default function Register() {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(initialErrors);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleRegister = (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        post(PATHS.REGISTER, data).then(result => {
            saveToken(result);
            dispatch(fetchUser());
            setLoading(false);
            dispatch(fetchSnackbar("Registrado con éxito."));
            history.replace(PATHS.HOME);
        }).catch(error => {
            setLoading(false);
            setErrors({...error.response.data.errors});
        });
    };

    return (
        <MyPanel
            header={(
                <Box>
                    <Header navButton={(
                        <Box marginTop={2}>
                            <MyLink to={PATHS.LOGIN}>Ingresar</MyLink>
                        </Box>
                    )}/>
                    <Box paddingX={5}>
                        <MyTitle name={"REGISTRO"}/>
                        <span>Crea una cuenta para acceder a nuestras ofertas de módulos de Viviendas de Interés Social - VIS</span>
                    </Box>
                </Box>
            )}
            content={(
                <Box>
                    <Box component={"form"} onSubmit={handleRegister} textAlign={"center"}>
                        <MyTextField
                            id="name"
                            label="Usuario"
                            name="name"
                        />
                        <MyTextField
                            id="documentNumber"
                            label="DNI"
                            name="document_number"
                        />
                        <MyTextField
                            type={"email"}
                            id="email"
                            label="Correo"
                            name={"email"}
                        />
                        <PasswordField
                            name={"password"}
                            label={"Contraseña"}
                            valid={errors.password[0] == null}
                            helperText={errors.password[0]}
                        />
                        <PasswordField
                            name={"password_confirmation"}
                            label={"Repetir Contraseña"}
                        />
                        <MyButton
                            type="submit"
                            loading={loading}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            REGISTRARSE
                        </MyButton>
                    </Box>
                </Box>
            )}
        />
    );
}