import {Box, Typography} from "@mui/material";

export default function MyTitle({label = null, name, color = "#fecb00"}) {
    return (
        <Box>
            {label && <Typography
                color={'black'}
                fontWeight={"bold"}
                fontSize={'15px'}
                lineHeight={'12px'}
            >
                {label}
            </Typography>}
            <Typography
                color={color}
                fontWeight={"bold"}
                fontSize={'28px'}
                lineHeight={'32px'}
                fontFamily={'Artegra'}
            >
                {name}
            </Typography>
        </Box>
    );
}