import {Box} from "@mui/material";
import MyTextField from "../tools/MyTextField";
import MyButton from "../tools/MyButton";
import * as PATHS from "../config/paths";
import {post} from "../services/api";
import InputButtonField from "../tools/InputButtonField";
import {AttachFile} from "@mui/icons-material";
import {useState} from "react";
import {fetchSnackbar} from "../redux/actions";
import {useDispatch} from "react-redux";

export default function BankAccount({contract}) {
    const dispatch = useDispatch();
    const [val, setVal] = useState(contract.receipt);
    const [loading, setLoading] = useState(false);

    const handleInput = (event) => {
        if (event.target.files.length) {
            setVal(event.target.files[0].name);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);
        post(`${PATHS.CONTRACT_BANK}/${contract.id}`, data).then(result => {
            setLoading(false);
            dispatch(fetchSnackbar("Enviado con éxito!"));
        }).catch(error => {
            setLoading(false);
            dispatch(fetchSnackbar(error.response.data.message, "error"));
        });
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <MyTextField
                id="number"
                label="Número de Cuenta"
                name="number"
                defaultValue={contract.bankAccount?.number}
                helperText={"Solo cuenta en BCP soles"}
            />
            <MyTextField
                id="holder"
                label="Nombre del Titular"
                name={"holder"}
                defaultValue={contract.bankAccount?.holder}
                helperText={"Información del titular de la cuenta"}
            />
            <MyTextField
                id="dni"
                label="DNI"
                name={"dni"}
                defaultValue={contract.bankAccount?.dni}
                helperText={"Doc. Nacional de Identidad del titular de la cuenta"}
            />
            <InputButtonField
                label="Boleta"
                value={val}
                iconButton={(
                    <label htmlFor={`receipt`}>
                        <input
                            style={{ display: 'none' }}
                            id={`receipt`}
                            name="receipt"
                            type="file"
                            onChange={handleInput}
                        />
                        <AttachFile/>
                    </label>
                )}
                helperText={"Descargar formato de boleta, llenar datos y subir (escaneo o foto legible)"}
            />
            <MyButton
                type={'submit'}
                loading={loading}
                color={"#e53b24"}
                sx={{marginTop: 2}}
            >
                ENVIAR
            </MyButton>
        </Box>
    );
}