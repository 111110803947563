import {
    Dialog,
    DialogTitle,
    List, ListItem, ListItemText
} from "@mui/material";

export default function FilterDialog({items, open, setOpen, setSelect}) {
    const handleListItemClick = (value) => {
        setSelect(value);
        setOpen(false)
    };
    return (
        <Dialog onClose={() => setOpen(false)} open={open}>
            <DialogTitle>Filtrar para:</DialogTitle>
            <List sx={{ pt: 0 }}>
                {items.map((item, index) => (
                    <ListItem button onClick={() => handleListItemClick(item)} key={index}>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}